:root {
  --loadingColor: #fdc74f;
  --loadingSize: 3px;
}
.App {
  text-align: center;
}
.App-header {
  /* background-color: #282c34 */
  background-color: #eee;
  min-height: 100vh;
  padding-top: 50px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: calc(10px + 2vmin);
  color: #222;
}

html, body, main, #root {
  width: 100%;
  height: auto;
}

.iframe {
  flex: 1;
}
.imagePreviewContainer {
  height: calc(100vh - 80px);
  width: 100%;
  overflow-y: auto;
}
.imagePreviewContainer.small {
  height: 100%;
  overflow-y: hidden;
}
.imagePreview {
  width: 98%;
  object-fit: contain;
  height: 100%;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.MuiInputBase-root {
  overflow: hidden;
}
.border-loading span {
  position: absolute;
  display: block;
}

.border-loading > .top {
  top: 0;
  left: -100%;
  width: 100%;
  height: var(--loadingSize);
  background: linear-gradient(90deg, transparent, var(--loadingColor));
  animation: top-animation 2s linear infinite;
}



.border-loading > .right {
  top: -100%;
  right: 0;
  width: var(--loadingSize);
  height: 100%;
  background: linear-gradient(180deg, transparent, var(--loadingColor));
  animation: right-animation 2s linear infinite;
  animation-delay: .5s
}



.border-loading > .bottom {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: var(--loadingSize);
  background: linear-gradient(270deg, transparent, var(--loadingColor));
  animation: bottom-animation 2s linear infinite;
  animation-delay: 1s
}



.border-loading > .left  {
  bottom: -100%;
  left: 0;
  width: var(--loadingSize);
  height: 100%;
  background: linear-gradient(360deg, transparent, var(--loadingColor));
  animation: left-animation 2s linear infinite;
  animation-delay: 1.5s
}

@keyframes left-animation {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}
@keyframes right-animation {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}
@keyframes bottom-animation {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}
@keyframes top-animation {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}